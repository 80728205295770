.sidebar {
    height: 100%;
    width: 180px;
    box-shadow: 0px 0px 20px 2px rgba(32, 32, 32, 0.326);
    z-index: 10;
}

.sidebar-item {
    display: block;
}

.site-logo {
    width: 100%;
    height: 60px;
    padding: 4px;
    background-color: #0b0b0b;
    text-align: center;
}

.site-logo img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 767px) {
    .sidebar {
        width: 60px;
    }
    .site-logo {
        padding: 0;
    }
    .site-logo img {
        padding-top: 15px;
    }
}