.game-description p {
    display: flex;
}
.game-description a:link, .game-description a:visited {
    color: #4af;
}
.game-description a:hover {
    color: #4cf;
}

.game-description a img:hover {
    transform: scale(1.05);
    transition: transform 0.1s;
}

.game-description ul {
    list-style: square;
    margin-left: 2em;
}

.game-description li {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
}

.game-rush-info li {
    margin-left: 2em;
}

.game-rush-info-container {
    height: auto;
    max-height: 6em;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
}

.game-rush-info-container.info-expanded {
    max-height: 50em;
}