.navbar {
    z-index: 11;
    height: 60px;
}

.navbar-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-inner {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    box-shadow: 5px 2px 9px 2px rgba(32, 32, 32, 0.326);
}

.navbar-item {
    display: block;
    padding: 1.12em;
}

.site-logo {
    text-align: center;
    display: block;
}