@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#root,
html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* background-color: #222235; */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  flex: 1;
  display: flex;
  z-index: 1;
}

.wallpaper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: no-repeat center url('./assets/images/wallpaper.jpg');
  background-size: cover;
  pointer-events: none;
  z-index: -1;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.box-shadow-sm {
  box-shadow: 2px 4px 10px 1px #101010;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1d24;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}